import { useEffect, useMemo, useRef, useState } from 'react'
import bold, { Device, Share } from '@/utils/bold-sdk'
import useAccount from '@/hooks/useAccount'
import useNotification from '@/hooks/useNotification'

type UseDevicesProps = {
    organizationId?: number
    deviceType?: 'Gateway' | 'Keyfob' | 'Lock'
    deviceGroupId?: number
    search?: string
    searchOnly?: boolean
    sortBy?: 'id' | 'name' | 'location' | 'remarks'
    sortDirection?: 'asc' | 'desc'
    size?: number
    includeShares?: boolean
}

type UseDevicesReturnValue = {
    data: DeviceWithShares[]
    loading: boolean
    offset: number
    fetch(offset?: number): Promise<void>
    loadMore(): Promise<void>
    update(data: DeviceWithShares[]): void
}

export type DeviceSharesMap = {
    [key: number]: {
        shares: Share[]
        complete: boolean
    }
}

export type DeviceWithShares = Device & {
    shares: Share[]
    sharesLoading: boolean
    sharesComplete: boolean
}

const useDevices = ({ organizationId, deviceType, deviceGroupId, search, searchOnly = false, sortBy, sortDirection, size = 20, includeShares = false }: UseDevicesProps): UseDevicesReturnValue => {

    const { data: account } = useAccount()
    const { handleError } = useNotification()
    const [ devices, setDevices ] = useState<Device[]>([])
    const [ deviceSharesMap, _setDeviceSharesMap ] = useState<DeviceSharesMap>({})
    const deviceSharesMapRef = useRef<DeviceSharesMap>({})
    const [ loading, setLoading ] = useState(false)
    const offset = useRef(0)

    const setDeviceSharesMap = (map: DeviceSharesMap) => {
        deviceSharesMapRef.current = map
        _setDeviceSharesMap(map)
    }

    const fetchShares = async (devices: Device[], offset: number) => {
        try {
            if (!devices.length) return
            const sharesMap = offset > 0 ? {...deviceSharesMapRef.current} : {}
            const { data } = await bold.device.getShares({
                deviceIds: devices.map(device => device.id),
                shareLimit: 6
            })
            for (const { deviceId, shares } of data) {
                sharesMap[deviceId] = { shares, complete: true }
            }
            setDeviceSharesMap(sharesMap)
        } catch (error) {
            handleError(error)
        }
    }

    const fetch = async (offset: number = 0) => {
        try {
            setLoading(true)
            if (offset === 0) {
                setDevices([])
                setDeviceSharesMap({})
            }
            let { data: newDevices } = await bold.device.getAll({
                organizationId,
                deviceType,
                deviceGroupId,
                search: (search && search.length) ? search : undefined,
                includeGroups: includeShares || false,
                sortBy,
                sortDirection,
                offset,
                size
            })
            if (offset > 0) setDevices([...devices,...newDevices])
            else setDevices(newDevices)
            if (includeShares) {
                fetchShares(newDevices, offset)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const loadMore = () => {
        offset.current += size
        return fetch(offset.current)
    }

    useEffect(() => {
        if (account) {
            // Make sure we're authenticated
            if (searchOnly && search && search.length > 0) fetch(0)
            else if (searchOnly) {
                setDevices([])
                setDeviceSharesMap({})
            }
            else if (!searchOnly) fetch(0)
        }
    }, [ account, organizationId, deviceType, deviceGroupId, search, searchOnly, sortBy, sortDirection, includeShares ])

    const data = useMemo<DeviceWithShares[]>(() =>
        devices.map(device => {
            const sharesMap = deviceSharesMap[device.id]
            return {
                ...device,
                shares: sharesMap?.shares ?? [],
                sharesLoading: sharesMap === undefined,
                sharesComplete: sharesMap?.complete ?? true
            }
        })
    , [ devices, deviceSharesMap ])

    return { data, loading, offset: offset.current, fetch, loadMore, update: setDevices }

}

export default useDevices
